@import "../../styles/styles.scss";

.sidemenu-container {
  background-color: $ui-menu;
  position: fixed;
  height: 100%;
  z-index: 5;
  top: 0;
  left: 0;
  overflow-x: hidden;
  display: none;

  @include media-breakpoint-up(lg) {
    width: 354px;
    display: block;
    -webkit-box-shadow: inset -8px 0px 16px -12px #000;
    box-shadow: inset -8px 0px 16px -12px #000;
  }
}

.sidemenu-opened {
  background: $ui-main-background;
  display: block;
  width: 100%;
  animation: move_from_left 350ms;

  .sidemenu-subtitle {
    color: $ui-main !important;
  }
}

@keyframes move_from_left {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

.sidemenu-content {
  position: relative;
  color: $ui-secondary !important;
  height: 100%;

  .accordion-title__item {
    text-transform: uppercase;
  }
}

.sidemenu-buttons {
  position: absolute;
  width: 100%;
  bottom: 0;

  .help-button {
    border-bottom-left-radius: 1px;
    background: transparent;
    color: #b3b7ce;
    border: 1px solid #b3b7ce;

    .icon {
      .mask {
        transition: all 0.15s linear;
        background-color: #b3b7ce !important;
      }
    }

    &:hover {
      color: #d6dae9;
      border: 1px solid #d6dae9;

      .icon {
        .mask {
          background-color: #d6dae9 !important;
        }
      }
    }
  }
}
