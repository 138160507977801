@import "../../styles/styles.scss";
.pagination-container {
  list-style-type: none;
  .pagination-buttons-container {
    background-color: white;
    border-radius: 24px;
  }
  .pagination-arrow {
    background: $ui-key;
    border-radius: 16px;
    height: 22px;
    width: 32px;

    &:hover {
      background-color: $ui-secondary;
      color: white;
      cursor: pointer;
    }
    &.disabled {
      pointer-events: none;
    }
  }
  .pagination-item {
    padding: 0 12px;
    background: $ui-key;
    height: 22px;
    width: 32px;
    border-radius: 24px;
    color: $brand-primary;
    box-sizing: border-box;
    letter-spacing: 0.01071em;
    border-radius: 16px;
    line-height: 1.43;
    font-size: 13px;
    min-width: 32px;

    &.dots {
      pointer-events: none;
    }
    &:hover {
      background-color: $brand-primary;
      color: white;
      cursor: pointer;
    }

    &.selected {
      background-color: $brand-primary;
      color: white;
    }

    &.disabled {
      pointer-events: none;

      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }
}
