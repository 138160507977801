@import "../../../styles/styles.scss";

.buy-albt-modal {
  &-screen {
    padding: 28px 0 0;
  }

  &-content {
    padding: 0 24px 16px;
  }

  &-protocols {
    background-color: $ui-element-background;
    border: 1px solid $ui-inactive;
    border-radius: 16px;
    padding: 20px 18px;
  }
}
