@import "../../../styles/styles.scss";

.transfer-confirmation-modal {
  &-screen {
    background-color: $ui-main;
    padding: 28px 0 48px;

    .icon:last-child > .mask {
      width: 64px !important;
      height: 64px !important;
    }
  }

  &-content {
    padding: 24px 24px 16px;
  }
}
