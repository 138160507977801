@import "../../../../styles/styles.scss";

.claim-listing {
  .container-header {
    box-shadow: none;
    padding: 0 25px;
  }
  .claim-wrappers {
    background-color: --uiElementBackground;
    border-radius: 16px;

    border: 1px solid $ui-web-background;
    .claim-table {
      min-height: 20vh;
      svg {
        width: 100%;
        height: 80%;
      }
      .icon-wrapper {
        background: $ui-key;
        min-width: 36px;
        height: 36px;
        border-radius: 11px;
      }
      .no-claims-wrapper {
        svg {
          height: 100%;
          width: 100%;
        }
      }
    }
  }
}
