@import "../../../styles/styles.scss";

.component-select {
  position: relative;

  .container-select-wrapper {
    min-width: 250px;
    position: relative;
    z-index: 2;
    background-color: $ui-main-background;
    border: 1px solid $ui-border;
    border-radius: 25px;
    color: $ui-main;

    &-md {
      padding: 14px 18px;
    }

    &-sm {
      padding: 10px 30px;
    }

    .select-dropdown {
      display: flex;
      flex-direction: row;
      position: relative;
      justify-content: space-between;
      z-index: 2;
      .text-main .icon {
        min-width: 18px;
      }
      .arrow {
        transition: 0.1s all ease-in-out;
        transform: rotate(-180deg);
      }

      &.active {
        .arrow {
          transform: rotate(0deg);
        }
      }
    }
  }

  .container-select-dropdown-list {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 52px;
    padding: 16px;
    width: 100%;
    min-width: 80px;
    list-style: none;
    border-bottom-right-radius: 25px;
    border-bottom-left-radius: 25px;
    box-shadow: var(--selectedWindowShadow);
    background-color: $ui-main-background;
    transition: all 300ms;

    &-md {
      padding-top: 42px;
    }

    &-sm {
      top: 26px;
    }

    &-show {
      max-height: 280px;
      overflow-y: auto;
      display: block;
      opacity: 1;
    }

    &-fade {
      display: none;
      opacity: 0;
    }

    li {
      color: $ui-main;
      cursor: pointer;
      line-height: 24px;
      padding: 16px 16px;
      display: flex;
      align-items: center;
      .icon {
        min-width: 18px;
      }
      .icon-check {
        path {
          fill: $ui-secondary;
        }
      }

      &:hover {
        color: $brand-primary;

        .icon-check {
          path {
            fill: $brand-primary;
          }
        }
      }

      &.selected {
        .icon-check {
          path {
            fill: $brand-primary;
          }
        }
      }
    }
  }
}
