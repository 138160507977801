@import "../../styles/styles.scss";

.checkbox {
  margin: auto;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;

  span {
    display: inline-block;
    vertical-align: middle;
    transform: translate3d(0, 0, 0);

    &:first-child {
      position: relative;
      width: 20px;
      height: 18px;
      border-radius: 3px;
      transform: scale(1);
      vertical-align: middle;
      border: 1px solid $ui-border;
      transition: all 0.2s ease;

      @include media-breakpoint-up(md) {
        width: 18px;
      }

      svg {
        position: absolute;
        top: 3px;
        left: 1px;
        fill: none;
        stroke: #fff;
        stroke-width: 2;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-dasharray: 16px;
        stroke-dashoffset: 16px;
        transition: all 0.3s ease;
        transition-delay: 0.1s;
        transform: translate3d(0, 0, 0);

        @include media-breakpoint-up(md) {
          left: 2px;
        }
      }

      &:before {
        content: "";
        width: 100%;
        height: 100%;
        background: $brand-primary;
        display: block;
        transform: scale(0);
        opacity: 1;
        border-radius: 50%;
      }
    }

    &:last-child {
      padding-left: 8px;
    }
  }

  &:hover span:first-child {
    border-color: $brand-primary;
  }
}

.input-checkbox {
  display: none;

  &:checked + .checkbox {
    span {
      &:first-child {
        background: $brand-primary;
        border-color: $brand-primary;
        animation: wave 0.4s ease;
      }
  
      svg {
        stroke-dashoffset: 0;
      }
  
      &:before {
        transform: scale(3.5);
        opacity: 0;
        transition: all 0.6s ease;
      }
    }
  }
}

@keyframes wave {
  50% {
    transform: scale(0.9);
  }
}
