@import "../../../styles/styles.scss";

@keyframes fade_in {
  0% {
    transform: translate(0, -50px);
  }

  100% {
    transform: none;
  }
}

@keyframes fade_out {
  100% {
    transform: translate(0, -50px);
  }
}

.modal-backdrop {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5);
  transition: visibility 250ms;
  visibility: hidden;

  &.show {
    visibility: visible;

    .modal-dialog {
      animation: fade_in 500ms;
    }
  }
}

.modal-dialog {
  background: $ui-main-background;
  padding: 16px;
  max-height: 100%;
  overflow-y: auto;
  animation: fade_out 500ms;

  .btn {
    transition: none !important;
  }

  @include media-breakpoint-up(sm) {
    border-radius: 16px;
    padding: 32px;
    max-height: calc(100% - 100px);
  }

  @include media-breakpoint-up(md) {
    width: 90%;
  }

  @include media-breakpoint-up(lg) {
    width: 800px;
  }

  .title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }

  .subtitle-container {
    margin-bottom: 24px;
  }
}
