@import "../../styles/styles.scss";

.full-md-width {
  @include media-breakpoint-down(md) {
    width: 100%;
  }
}

.styled-hr {
  border: none;
  border-top: 0.1rem solid $ui-inactive;
  border-radius: 2px;
}
.dropdown-content-container .content {
  @include media-breakpoint-down(md) {
    margin-left: 40%;
  }
}
