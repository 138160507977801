@import "../../../styles/styles.scss";

.bridge-stepper {
  .step-panel:last-child {
    background-color: white !important;
    padding: 16px 0 !important;

    @include media-breakpoint-up(lg) {
      padding: 16px !important;
    }
  }

  .stepper {
    padding: 16px 8px;

    @include media-breakpoint-up(lg) {
      padding: 16px;
    }
  }
}
