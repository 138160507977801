@import "../../../styles/styles.scss";

.claim-page-container {
  .data-wrapper {
    .claimed-option-wrapper {
      @include media-breakpoint-down(md) {
        flex-direction: column;
        .dropdown-option-wrapper {
          flex-direction: column;
          justify-content: space-between;
          margin-bottom: 1rem;
        }
        .claimed-wrapper {
          justify-content: space-between;
          .claimed-number {
            width: 50%;
          }
          .claimed-title {
            text-align: center;
            width: 50%;
          }
        }
        .component-select {
          width: 100%;
          .container-select-wrapper {
            width: 100%;
          }
        }
      }
    }
  }
}
