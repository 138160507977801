@import "../../../styles/styles.scss";

.faucet-mining-success-modal {
  &-screen {
    background-color: $ui-main;
    padding: 28px 0 48px;

    .icon:last-child > .mask {
      width: 78px !important;
      height: 78px !important;
    }
  }

  &-content {
    padding: 24px 24px 16px;
  }
}
