@import "../../../../../styles/styles.scss";
.mobile-row-container {
  // closed row
  .closed-row-wrapper {
    .source-chain-icon-wrapper {
      .icon {
        padding-right: 5px;
      }
    }
    .logo {
      svg {
        max-width: 21px;
        max-height: 21px;
        width: 100%;
        height: 100%;
      }
    }
    .amount-wrapper {
      .token span {
        margin-left: 5px;
      }
    }
  }

  // opened row
  .opened-row-wrapper {
    border: 1px solid $ui-border;
    box-shadow: var(--selectedWindowShadow);
    border-radius: 16px;
    .logo {
      width: 20px;
      svg {
        max-width: 21px;
        max-height: 21px;
        width: 100%;
        height: 100%;
      }
    }
    .source-data-wrapper {
      box-shadow: var(--selectedWindowShadow);
      .source-target-chain-data {
        .logo {
          width: 20px;
        }
      }
      .amount-data-container {
        .token-amount-wrapper {
          span {
            margin-right: 5px;
          }
          div .icon {
            margin-right: 5px;
          }
        }
      }
    }
    .button-container {
      border-radius: 12px;
      box-shadow: var(--selectedWindowShadow);
      .button-box {
        button {
          width: 100%;
        }
      }
    }
  }
  .hidden-icon {
    visibility: hidden;
  }
  .trimmed-amount {
    min-width: 120px;
    text-align: right;
    @media (pointer: coarse), (hover: none) {
      [title] {
        position: relative;
        display: flex;
        justify-content: center;
      }
      [title]:focus::after {
        content: attr(title);
        position: absolute;
        top: 90%;
        color: #000;
        background-color: #fff;
        border: 1px solid;
        width: fit-content;
        padding: 3px;
      }
    }
  }
}
