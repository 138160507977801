@import "../../../styles/styles.scss";

.claim-success-modal {
  &-screen {
    background-color: $ui-main;

    .icon:last-child > .mask {
      width: 78px !important;
      height: 78px !important;
    }
  }
}
