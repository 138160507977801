@import "../../../../../styles/styles.scss";

.container-claim-header {
  position: relative;
  height: 2.5vh;
  .source-chain {
    position: absolute;
    left: 10%;
  }
  .target-chain {
    position: absolute;
    left: 33%;
  }
  .amount-h-wrapper {
    position: absolute;
    left: 59.33%;
    min-width: 90px;
  }
  .status-header {
    position: absolute;
    right: 2vw;
  }
}
