@import "../../styles/styles.scss";

.accordion-section {
  display: flex;
  flex-direction: column;

  ul {
    padding-left: 4px;
    margin-top: 5px;

    .accordion-item {
      display: block;
      margin-top: 10px;
      margin-bottom: 10px;
      padding: 10px;
      border-radius: 20px;
      transition: all 200ms ease;
      text-decoration: none;
      color: $ui-main;

      .icon:first-child {
        .mask {
          background-color: $ui-main !important;
        }
      }

      @include media-breakpoint-up(lg) {
        color: inherit;

        .icon:first-child {
          .mask {
            background-color: $ui-secondary !important;
          }
        }
      }

      &:hover {
        background-color: $ui-web-background;

        @include media-breakpoint-up(lg) {
          color: white;
          background-color: $ui-contrast;

          .icon:first-child {
            .mask {
              background-color: $ui-solid !important;
            }
          }
        }
      }

      &.active {
        background-color: $ui-web-background;

        @include media-breakpoint-up(lg) {
          color: white;
          background-color: $ui-contrast;

          .icon:first-child {
            .mask {
              background-color: $ui-solid !important;
            }
          }
        }
      }

      .quantity-number {
        background-color: $brand-primary;
        color: white;
        padding-left: 14px;
        padding-right: 14px;
        border-radius: 20px;
        margin-left: auto;
      }
    }
  }
}

.accordion-title {
  cursor: pointer;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  transition: background-color 0.6s ease;
  padding: 8px 16px;

  &__item {
    font-weight: 600;
    font-size: 14px;
    color: inherit;
  }

  .arrow {
    transition: 0.1s all ease-in-out;
    transform: rotate(-180deg);

    &.rotated {
      transform: rotate(0);
    }
  }
}

.accordion-content {
  overflow: hidden;
  transition: max-height 0.6s ease;
  font-size: 13px;
}
