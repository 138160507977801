@import "../../styles/styles.scss";

.indicator {
  border-radius: 16px;
  box-shadow: $top-bar-shadow;
  width: 100%;

  &-value {
    border-radius: 50px;
    color: $ui-main-background;
    background-color: $ui-main;

    &.bg-primary {
      background-color: $ui-main-background;
    }

    &.bg-secondary {
      background-color: $brand-primary;
    }
  }

  &.bg-primary {
    background-color: $ui-main-background;
  }

  &.bg-secondary {
    background-color: $brand-primary;
  }

  &.box-shadow-primary {
    box-shadow: 0px 8px 24px rgba(233, 234, 244, 0.8);
  }

  &.box-shadow-secondary {
    box-shadow: 0px 7px 19px rgba(84, 102, 253, 0.52);
  }
}
