@import "../../../../styles/styles.scss";

.btn[disabled] {
  border-color: $ui-border !important;
  color: $ui-secondary !important;
  cursor: not-allowed !important;
  background-color: white !important;
  pointer-events: none !important;
  .icon .mask {
    background-color: $ui-secondary !important;
    cursor: not-allowed;
  }
}
