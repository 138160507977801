@import "../../styles/styles.scss";

.container-header {
  background: $ui-main-background;
  box-shadow: $top-bar-shadow;
  padding: 12px 20px;
  position: fixed;
  width: 100%;
  z-index: 8;

  @include media-breakpoint-up(md) {
    padding: 24px 24px;
  }

  @include media-breakpoint-up(lg) {
    width: calc(100% - 355px);
  }

  .container-select-dropdown-list {
    height: 280px;
    overflow-y: scroll;
  }

  .network-select-component .container-select-wrapper {
    padding: 10px 20px 10px 28px;
  }
}
